@import './../../components/sitetheme.module.scss';

.header {
    text-transform: lowercase;
}
.details {
    margin: 1rem 0;
    padding-bottom: var(--std-padding-vertical);
    text-transform: lowercase;
    font-family: monospace;

    .title {
        display: inline-block;
        letter-spacing: 0.2rem;
        font-weight: bold;
        font-size: x-large;
    }
    .actions {
        display: inline-block;

        a {
            text-decoration-line: underline;
            text-decoration-style: dashed;
        }
    }
    .line1 {
        display: flex;
        justify-content: space-between;
    }
    .line2 {
        margin-top: var(--std-margin-vertical);
    }
}
