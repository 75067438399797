.animComponent {
    position: fixed;
    width: 100vw;
    height: 100vw;
    bottom: -50vw;
    right: -50vw;

    transform: translate(-2vw, -2vw);

    z-index: -1;

    canvas {
        background-color: transparent !important;
    }
}