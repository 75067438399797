@import "./../../components/sitetheme.module.scss";

.banner {
    font-weight: bold;

    h1 {
        letter-spacing: 1rem;
        text-transform: uppercase;
    }
    p {
        margin-top: 2rem;
        text-transform: lowercase;
    }
    p.links {
        a {
            text-decoration-line: overline;
            text-decoration-style: dotted;
            text-decoration: overline dotted;
        }
        a + a {
            margin-left: var(--std-margin-horizontal);
        }
    }
}
.body {
    div.heading {
        display: flex;
        align-items: center;

        span {
            margin-left: 50%;
        }
    }
    div.projects {
        margin: var(--std-margin-vertical) 0;

        details {
            display: inline-block;
            width: calc((100% / 3) - 2 * var(--std-margin-horizontal));
            margin-top: var(--std-margin-vertical);
            margin-right: var(--std-margin-horizontal);
            vertical-align: text-top;

            text-transform: lowercase;

            @media screen and (max-width: 550px) {
                width: auto;
                display: block;
            }

            summary {
                cursor: pointer;
                outline: 0;
                user-select: none;
                font-weight: bold;
            }
        }
        details[open] {
            margin-bottom: 0;
        }
    }
    a {
        text-decoration-line: underline;
        text-decoration-style: dotted;
        color: var(--secondary-color);
        background-color: var(--primary-color);

        transition: color var(--std-transition-time) ease,
            background-color var(--std-transition-time) ease;
    }
}
