@import './../../components/sitetheme.module.scss';

.linksList {
    text-align: center;

    a {
        display: block;
        font-size: large;
        text-decoration: underline dotted;
        margin: var(--std-margin-vertical) 0;
    }
}
