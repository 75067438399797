* {
    box-sizing: border-box;
}

body {
    /* color inspiration from: https://www.viget.com/articles/color-contrast/ */
    --primary-color: #131516;
    --secondary-color: #ffffff;
    --std-padding-vertical: 3rem;
    --std-padding-horizontal: 5rem;
    --std-padding: var(--std-padding-vertical) var(--std-padding-horizontal);
    --std-margin-vertical: 1rem;
    --std-margin-horizontal: 2rem;
    --std-margin: var(--std-margin-vertical) var(--std-margin-horizontal);
    --std-transition-time: 0.3s;
    --std-page-transition-time: 0.3s;

    color: var(--primary-color);

    transition: color var(--std-transition-time) ease;
}
body::before {
    content: '';

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    /* background-color: #093145; */
    background-color: #363946;
    opacity: 0;

    z-index: -1;

    transition: opacity var(--std-transition-time) ease;
}
body.dark {
    --primary-color: #ffffff;
    /* --secondary-color: #093145; */
    --secondary-color: #363946;
}
body.dark::before {
    opacity: 1;
}

@media screen and (max-width: 768px) {
    body {
        --std-padding-horizontal: 2rem;
    }
}

a {
    cursor: pointer;
    color: var(--primary-color);

    transition: color var(--std-transition-time) ease,
        background-color var(--std-transition-time) ease;
}

/* animated switch */

.router-transition-enter {
    opacity: 0;
    transform: translateY(1rem);
    transition: opacity var(--std-page-transition-time) ease-out,
        transform var(--std-page-transition-time) ease;
}
.router-transition-exit {
    opacity: 1;
    transform: none;
    transition: opacity var(--std-page-transition-time) ease-out,
        transform var(--std-page-transition-time) ease;
}
.router-transition-enter.router-transition-enter-active {
    opacity: 1;
    transform: none;
}
.router-transition-exit.router-transition-exit-active {
    opacity: 0;
    transform: translateY(-1rem);
}
