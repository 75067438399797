@import "./../sitetheme.module.scss";

.nav {
    padding: var(--std-padding);
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
        flex-direction: column;
    }

    div {
        display: inline-block;
        cursor: pointer;
        word-wrap: break-word;
        user-select: none;

        span,
        a {
            white-space: nowrap;
            text-decoration-line: underline;
            text-decoration-style: dotted;
            text-decoration: underline dotted;

            margin-right: var(--std-margin-horizontal);
        }
    }

    .left {
        text-transform: uppercase;

        @media screen and (max-width: 550px) {
            margin-bottom: var(--std-margin-vertical);
        }
    }
    .right {
        text-transform: lowercase;
    }
}
