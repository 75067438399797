.card {
    border-left: 1rem solid var(--primary-color);
    padding-left: var(--std-padding-horizontal);
    margin-bottom: var(--std-margin-vertical);

    transition: border-color var(--std-transition-time) ease;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            text-decoration-line: underline;
            text-decoration-style: dotted;
            text-transform: lowercase;
        }
    }
    .body {
        padding: var(--std-margin-vertical) 0;
    }
}
