.page {
    --std-max-width: 70rem;

    .header {
        padding: var(--std-padding);
    }
    .header.minor {
        padding-bottom: 0;
        padding-top: 0;
        max-width: var(--std-max-width);
        margin: auto;

        h1 {
            text-transform: lowercase;
        }
    }
    .body {
        max-width: var(--std-max-width);
        margin: auto;
        padding: var(--std-padding);
    }
    .body.expand {
        margin: 0;
    }
}
