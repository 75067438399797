@import "./../sitetheme.module.scss";

.typing {
    overflow: hidden;
    border-right: 0.3rem solid transparent;
    // white-space: nowrap;
    margin: 0 auto;

    animation: blink-pointer 0.55s step-end 17;
}

@keyframes blink-pointer {
    from {
        border-color: transparent;
    }
    50% {
        border-color: var(--primary-color);
    }
    to {
        border-color: transparent;
    }
}
